import {Injectable} from '@angular/core';

@Injectable()
export class SharedService {

  private static cachedWindowName: string = null;

  static isOpenFin(): boolean {
    return typeof fin !== 'undefined' && 'desktop' in fin;
  }

  static currentWindowName(): string {
    if (this.isOpenFin()) {
      if (!this.cachedWindowName) {
        this.cachedWindowName = fin.desktop.Window.getCurrent().name;
      }
      return this.cachedWindowName;
    }
  }

  static currentWindow(): any {
    return fin.desktop.Window.getCurrent();
  }

}
