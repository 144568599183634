import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/mainframe', pathMatch: 'full' },
  { path: 'mainframe', loadChildren: () => import('app/+main-frame/main-frame.module').then(m => m.MainFrameModule) },
  { path: 'login', loadChildren: () => import('app/+login/login.module').then(m => m.LoginModule) },
  { path: 'change-password', loadChildren: () => import('app/+password-manager/password-manager.module').then(m => m.PasswordManagerModule) },
  { path: 'blotter', loadChildren: () => import('app/+trades-blotter/trades-blotter.module').then(m => m.TradesBlotterModule) },
  { path: 'manual-trade-ticket', loadChildren: () => import('app/+manual-trade-ticket/manual-trade-ticket.module').then(m => m.ManualTradeTicketModule)},
  { path: 'auctions', loadChildren: () => import('app/+auctions/auctions.module').then(m => m.AuctionsModule)},
  { path: 'administration', loadChildren: () => import('app/+administration/administration.module').then(m => m.AdministrationModule)}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
