import { SharedService } from './services/shared.service';

export class Logger {

  private static readonly MESSAGE_TYPE_LOG: string = 'log';
  private static readonly MESSAGE_TYPE_ERROR: string = 'error';
  private static readonly MESSAGE_TYPE_INPUT: string = 'io';

  public static logLevel: number = 1;

  private static logCounter: number = 0;
  private static readonly clearLogsAfter: number = 100;

  public static log(msg, context, object = null): void {
    this.prepareMessage(msg, context, object, this.MESSAGE_TYPE_LOG);
  }

  public static logBusMessage(topic, message): void {
    if (message && message.action == 'HEARTBEAT') {
      return;
    }
    const avoidLoggingData = ['LOGIN', 'USER_INFO', 'GET_USER', 'GET_LISTS_DATA', 'GET_TICKET', 'GET_FIRMS', 'UPDATE_DATA',
      'CHANGE_PASSWORD', 'GET_DROPDOWN_DATA', 'GET_TRADERS_DATA', 'GET_ORDER_INFO', 'GET_DATA', 'SAVE_MANUAL_TRADE_TICKET',
    'GET_LISTS_LEGS_DATA'];
    const msg = message ? 'send topic: ' + topic + ' action: ' + message.action + ' windowName: '
      + SharedService.currentWindowName() : '';
    if (message && !avoidLoggingData.includes(message.action)) {
      Logger.log(msg,'Bus', message.payload ? message.payload : null);
    } else {
      Logger.log(msg,'Bus');
    }
  }

  public static error(msg, context: string = '', object = null): void {
    if (typeof msg === 'object') {
      if (msg.message) {
        this.prepareMessage(msg.message, context, object, this.MESSAGE_TYPE_ERROR);
      } else {
        this.prepareMessage(JSON.stringify(msg), context, object, this.MESSAGE_TYPE_ERROR);
      }
      return;
    }
    this.prepareMessage(msg, context, object, this.MESSAGE_TYPE_ERROR);
  }

  public static userInput(msg, context: string = '', object = null): void {
    this.prepareMessage(msg, context, object, this.MESSAGE_TYPE_INPUT);
  }

  public static trace(msg, context): void {
    if (this.logLevel === 0) {
      let output: string = '';
      if (typeof msg === 'string' || typeof msg === 'number') {
        output = context + ' - ' + msg;
      } else {
        output = context + ' - ' + JSON.stringify(msg);
      }
      console.log(output);
    }
  }

  private static prepareMessage(msg, context: string = '', object = null, messageType): void {
    let output = msg;
    if (context) {
      output = context + ' - ' + output;
    }
    if (object) {
      output += '. Data: ' + JSON.stringify(object);
    }

    if (messageType === this.MESSAGE_TYPE_LOG || messageType === this.MESSAGE_TYPE_INPUT) {
      this.consoleLog(output);
    } else if (messageType === this.MESSAGE_TYPE_ERROR) {
      this.consoleError(output)
    }
  }

  private static consoleLog(msg): void {
    console.log(msg);
    this.onNewLog();
  }

  private static consoleError(msg): void {
    console.error(msg);
    this.onNewLog();
  }

  private static onNewLog(): void {
    this.logCounter++;
    if (this.logCounter > this.clearLogsAfter) {
      this.logCounter = 0;
      this.clear();
    }
  }

  private static clear(): void {
    console.clear();
  }

}
