export const environment = {
  production: true,
  version: '0.0.727',
  BASE_URL: null,
  STOMP_SOCKET: null,
  KEYCLOAK_URL: null,
  KEYCLOAK_REALM: 'edf',
  KEYCLOAK_CLIENT_ID: 'swap-connect-ui'

};
