import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {AppRoutingModule} from "./app.routing.module";
import { OverlayModule } from '@angular/cdk/overlay';
import {environment} from "../environments/environment";


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    environment.BASE_URL = window.location.origin;
    environment.KEYCLOAK_URL = window.location.origin + '/auth';
    environment.STOMP_SOCKET = window.location.origin + '/api/sc-socket';
  }
}
