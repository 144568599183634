export class KeyboardHelper {

  private static keysToDisable = ['Tab', 'Enter', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowLeft'];
  private static isSubscribed: boolean = false;

  public static disableNavigationKeys() {
    if (this.isSubscribed) {
      return;
    }
    window.addEventListener('keydown', this.disableNavigationKeysListener);
  }

  public static enableNavigationKeys() {
    if (!this.isSubscribed) {
      return;
    }
    window.removeEventListener('keydown', this.disableNavigationKeysListener);
  }

  private static disableNavigationKeysListener = (event) => {
    if (KeyboardHelper.keysToDisable.includes(event.code)) {
      event.preventDefault();
    }
  };

}
