import { Logger } from '../logger';

export class OpenfinHelper {

  public static restart(): void {
    Logger.log('restart', 'OpenfinHelper');
    fin.desktop.Application.getCurrent().restart();
  }

  public static hide(): void {
    fin.desktop.Window.getCurrent().hide();
  }

  public static zoneIntercept(zone, callback) {
    const originalCallback = callback;
    const thatZone = zone;
    return function interceptor() {
      const args = arguments;
      thatZone.run(() => originalCallback.apply(null, args));
    };
  }

}
