import {SharedService} from "../services/shared.service";
import { Logger } from '../logger';

export class IABusHelper {

  static readonly APP_COMMAND_TOPIC: string = 'edf-sc-app-command-topic';
  static readonly APP_MESSAGE_TOPIC: string = 'edf-sc-app-message-topic';

  static readonly DIALOG_COMMAND_TOPIC: string = 'edf-sc-dialog-command-topic';

  static readonly WINDOW_COMMAND_TOPIC: string = 'edf-sc-window-command-topic';
  static readonly WINDOW_MESSAGE_TOPIC: string = 'edf-sc-window-message-topic';

  static readonly AUTH_COMMAND_TOPIC: string = 'edf-sc-auth-command-topic';
  static readonly AUTH_MESSAGE_TOPIC: string = 'edf-sc-auth-message-topic';

  static readonly TRAY_COMMAND_TOPIC: string = 'edf-sc-tray-command-topic';
  static readonly TRAY_MESSAGE_TOPIC: string = 'edf-sc-tray-message-topic';

  static readonly BLOTTER_COMMAND_TOPIC: string = 'edf-sc-blotter-command-topic';
  static readonly BLOTTER_MESSAGE_TOPIC: string = 'edf-sc-blotter-message-topic';

  static readonly MANUAL_TRADE_TICKET_COMMAND_TOPIC: string = 'edf-sc-manual-trade-ticket-command-topic';
  static readonly MANUAL_TRADE_TICKET_MESSAGE_TOPIC: string = 'edf-sc-manual-trade-ticket-message-topic';

  static readonly AUCTION_COMMAND_TOPIC: string = 'edf-sc-auction-command-topic';
  static readonly AUCTION_MESSAGE_TOPIC: string = 'edf-sc-auction-message-topic';

  static readonly ADMINISTRATION_COMMAND_TOPIC: string = 'edf-sc-administration-command-topic';
  static readonly ADMINISTRATION_MESSAGE_TOPIC: string = 'edf-sc-administration-message-topic';

  static readonly DW_NOTIONAL_OVERRIDE: string = 'edf-sc-dw-notional-override';
  static readonly LEG_TERM_DATE_OVERRIDE: string = 'edf-sc-term-date-override';
  static readonly FLOAT_LEG_INDEX_CHANGE: string = 'edf-sc-leg-float-index-change';
  static readonly FLOAT_LEG_INDEX_CHANGE_FOR_INSTRUMENT_LABEL: string = 'edf-sc-leg-float-index-change-for-instrument-label';
  static readonly FRA_LEG_INDEX_CHANGE_FOR_INSTRUMENT_LABEL: string = 'edf-sc-leg-fra-index-change-for-instrument-label';

  static readonly BROKERAGE_COMMAND_TOPIC: string = 'edf-sc-brokerage-command-topic';
  static readonly BROKERAGE_MESSAGE_TOPIC: string = 'edf-sc-brokerage-message-topic';

  private static readonly contextName: string = 'Bus';

  static send(topic: string, message: any) {
    Logger.logBusMessage(topic, message);
    fin.desktop.InterApplicationBus.send(
      (<any>fin.desktop.Application.getCurrent()).uuid,
      topic, message);
  }

  static dialog(dialogInfo) {
    Logger.log('dialog',this.contextName, dialogInfo);
    IABusHelper.send(IABusHelper.APP_COMMAND_TOPIC, {action: 'DISPLAY_DIALOG', payload: dialogInfo});
  }

  static publish(topic: string, message: any) {
    fin.desktop.InterApplicationBus.publish(topic, message);
  }

  static subscribe(topic: string, listener: any, successCallback?: Function) {
    fin.desktop.InterApplicationBus.subscribe('*',
      topic,
      listener,
      () => {
        Logger.log('Subs. ' + SharedService.currentWindowName() + ' to ' + topic, this.contextName);
        if (successCallback) {
          successCallback();
        }
      },
      (error) => {
        Logger.error('Could not subscribe to ' + topic, this.contextName);
        Logger.error(error, this.contextName);
      }
      );
  }

  static unsubscribe(topic: string, listener: any) {
    fin.desktop.InterApplicationBus.unsubscribe('*',
      topic,
      listener,
      () => {
        Logger.log('Unsubs. from ' + topic, this.contextName);
      },
      (error) => {
        Logger.error('Could not unsubscribe from ' + topic, this.contextName);
        Logger.error(error);
      }
    );
  }

}
