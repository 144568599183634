import { Component, NgZone } from '@angular/core';

import { IABusHelper } from './shared/helpers/ia.bus.helper';
import { OpenfinHelper } from './shared/helpers/openfin.helper';
import { KeyboardHelper } from './shared/helpers/keyboard.helper';
import { UserInteractionTracker } from './shared/helpers/user-interaction-tracker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  public isConnected: boolean = true;
  public href: string;
  public disconnectOverlayTopOffset: number = 50;

  constructor(private zone: NgZone) {
    const appMessageListenerIntercept = OpenfinHelper.zoneIntercept(this.zone, this.appMessageListener);
    IABusHelper.subscribe(IABusHelper.APP_MESSAGE_TOPIC, appMessageListenerIntercept);

    const locationHref = window.location.href;

    if (locationHref.includes('auctions/flash-config')) {
      this.disconnectOverlayTopOffset = 0;
    }
    if (!locationHref.includes('/login') && !locationHref.includes('/change-password')) {
      UserInteractionTracker.trackActions();
    }
  }

  private appMessageListener = (message) => {
    switch (message.action) {
      case 'CONNECTION_LOST':
        this.isConnected = false;
        KeyboardHelper.disableNavigationKeys();
        break;
      case 'CONNECTION_RESTORED':
        this.isConnected = true;
        KeyboardHelper.enableNavigationKeys();
        break;
    }
  };

}
