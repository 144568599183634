import { Logger } from '../logger';

export class UserInteractionTracker {

  private static windowName: string = '';

  public static trackActions() {
    this.setWindowName();
    window.document.addEventListener('keydown', (event) => {
      Logger.userInput('code: ' + event.code
        + ' shift: ' + event.shiftKey
        + ' target: ' + this.dataFromHtmlElement(event.target) + ' val: ' + event.target['value'], 'KeyDown ' + this.windowName);
    });

    window.document.addEventListener('click', (event) => {

      Logger.userInput('target: ' + this.dataFromHtmlElement(event.target), 'Mouse Click ' + this.windowName);
    });
  }

  private static setWindowName(): void {
    if (!this.windowName) {
      this.windowName = fin.desktop.Window.getCurrent().name.replace('edf-sc-', '');
    }
  }

  private static dataFromHtmlElement(el): string {
    let id = el.id ? '#' + el.id : '';
    return el.tagName + ' ' + id + ' class: ' + el.className + ' text: ' + event.target['innerText'].substring(0,25);
  }

}
